import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import HeaderNavigation from './components/HeaderNavigation';
import MyBadgePage from './pages/MyBadgePage';
import ProfilePage from './pages/ProfilePage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { useUserStore } from './stores/userStore';
import { UserSettingsPage } from './pages/UserSettingsPage';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';
import HomePage from './pages/HomePage';
import { DefaultMetaTags } from './components/DefaultMetaTags';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { VerifyEmailPage } from './pages/VerifyEmailPage';
import { Spinner } from './components/ui/Spinner';
import OnboardingPage from './pages/OnboardingPage';
import { ErrorBoundary } from './components/ErrorBoundary';
import SkoolPage from './pages/SkoolPage';
import Home from './pages/Home';
import Platform from './pages/Platform'
import RingsTest from './pages/RingsTest';
import NewsletterPage from './pages/Newsletter';
import ThankYouPage from './pages/ThankYouPage';
import RemindMePage from './pages/RemindMePage';
import PrivacyPolicy from './pages/PrivacyPolicy';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentUser, isEmailVerified } = useAuth();
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      if (currentUser) {
        await currentUser.reload();
      }
      setIsChecking(false);
    };

    checkAuth();
  }, [currentUser]);

  if (isChecking) {
    return <Spinner />;
  }

  if (!currentUser) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!isEmailVerified && location.pathname !== '/verify-email') {
    return <Navigate to="/verify-email" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const MainContent: React.FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const hostname = window.location.hostname;
  useGoogleAnalytics();
  
  // Common pages that should be available on both domains
  const commonRoutes = (
    <Routes>
      <Route path="/verify-email" element={<VerifyEmailPage />} />
      <Route path="/onboarding" element={
        currentUser ? <Navigate to="/dashboard" /> : <OnboardingPage />
      } />
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <MyBadgePage />
        </ProtectedRoute>
      } />
      <Route path="/settings" element={
        <ProtectedRoute>
          <UserSettingsPage />
        </ProtectedRoute>
      } />
      <Route path="/u/:userId" element={<ProfilePage />} />
    </Routes>
  );

  // If we're on ctrlai.ai
  if (hostname === 'ctrlai.ai' || hostname === 'www.ctrlai.ai') {
    return (
      <HelmetProvider>
        <div className="min-h-screen bg-gradient-radial from-background to-background-end text-foreground">
          <DefaultMetaTags />
          <HeaderNavigation />
          <main className="container mx-auto px-4 py-4 md:py-8">
            <Routes>
              <Route path="/" element={<Platform />} />
              {commonRoutes}
            </Routes>
          </main>
        </div>
      </HelmetProvider>
    );
  }

  // Original ctrlai.com routes
  return (
    <HelmetProvider>
      <div className="min-h-screen bg-gradient-radial from-background to-background-end text-foreground">
        <DefaultMetaTags />
        <HeaderNavigation />
        <main className="container mx-auto px-4 py-4 md:py-8">
          <Routes>
            <Route path="/" element={!currentUser ? <HomePage /> : <Navigate to="/dashboard" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/platform" element={<Platform />} />
            <Route path="/rings-test" element={<RingsTest />} />
            <Route path="/newsletter" element={<NewsletterPage />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/remind-me" element={<RemindMePage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            {commonRoutes}
          </Routes>
        </main>
      </div>
    </HelmetProvider>
  );
};

const App: React.FC = () => {
  const hostname = window.location.hostname;
  
  // If we're on ctrlai.ai, only render the Platform page
  if (hostname === 'ctrlai.ai' || hostname === 'www.ctrlai.ai') {
    return (
      <ErrorBoundary>
        <Router>
          <AuthProvider>
            <HelmetProvider>
              <div className="min-h-screen bg-gradient-radial from-background to-background-end text-foreground">
                <HeaderNavigation />
                <main className="container mx-auto px-4 py-4 md:py-8">
                  <Routes>
                    <Route path="/" element={<Platform />} />
                    <Route path="/verify-email" element={<VerifyEmailPage />} />
                    <Route path="/onboarding" element={
                      <ProtectedRoute>
                        <OnboardingPage />
                      </ProtectedRoute>
                    } />
                    <Route path="/dashboard" element={
                      <ProtectedRoute>
                        <MyBadgePage />
                      </ProtectedRoute>
                    } />
                    <Route path="/settings" element={
                      <ProtectedRoute>
                        <UserSettingsPage />
                      </ProtectedRoute>
                    } />
                    <Route path="/u/:userId" element={<ProfilePage />} />
                  </Routes>
                </main>
              </div>
            </HelmetProvider>
          </AuthProvider>
        </Router>
      </ErrorBoundary>
    );
  }

  // Original app for ctrlai.com - only marketing/info pages
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <HelmetProvider>
            <div className="min-h-screen bg-gradient-radial from-background to-background-end text-foreground">
              <HeaderNavigation />
              <main className="container mx-auto px-4 py-4 md:py-8">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/rings-test" element={<RingsTest />} />
                  <Route path="/newsletter" element={<NewsletterPage />} />
                  <Route path="/thank-you" element={<ThankYouPage />} />
                  <Route path="/remind-me" element={<RemindMePage />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  {/* Redirect all platform-related routes to ctrlai.ai */}
                  <Route path="/dashboard" element={<Navigate to="https://ctrlai.ai/dashboard" replace />} />
                  <Route path="/settings" element={<Navigate to="https://ctrlai.ai/settings" replace />} />
                  <Route path="/platform" element={<Navigate to="https://ctrlai.ai" replace />} />
                  <Route path="/u/*" element={<Navigate to="https://ctrlai.ai/u/:userId" replace />} />
                </Routes>
              </main>
            </div>
          </HelmetProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
