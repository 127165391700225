import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/button';
import { SignInModal } from './SignInModal';
import { ThemeToggle } from './ThemeToggle';
import { Menu } from 'lucide-react';
import { cn } from '../lib/utils';
import { useUserStore } from '../stores/userStore';

const HeaderNavigation: React.FC = () => {
  const location = useLocation();
  const { currentUser, signOut } = useAuth();
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const hostname = window.location.hostname;
  const isAIDomain = hostname === 'ctrlai.ai' || hostname === 'www.ctrlai.ai';

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setIsVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setIsScrolled(currentScrollPos > 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <header 
      className={cn(
        'bg-white dark:bg-gray-950 shadow-sm fixed top-0 left-0 right-0 z-50 h-[80px] transition-transform duration-300',
        isScrolled ? 'border-b border-gray-200 dark:border-gray-800' : '',
        !isVisible ? '-translate-y-full' : 'translate-y-0'
      )}
    >
      <div className="container mx-auto h-full px-8 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <div 
            className="logo bg-contain bg-no-repeat bg-center h-24 w-40"
            aria-label="Ctrl AI Logo" 
          />
        </Link>
        
        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-4 h-full">
          <nav className="flex items-center space-x-6 mr-4 h-full">
            {isAIDomain ? (
              // AI domain navigation items
              <div className="flex items-center space-x-6">
                {currentUser && (
                  <>
                    <Link 
                      to="/dashboard" 
                      className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
                    >
                      Dashboard
                    </Link>
                    <Link 
                      to={`/u/${currentUser.uid}`} 
                      className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
                    >
                      My Profile
                    </Link>
                    <Link 
                      to="/settings" 
                      className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
                    >
                      Settings
                    </Link>
                  </>
                )}
              </div>
            ) : (
              // Original navigation items for ctrlai.com
              <div className="flex items-center space-x-6">
                <Link 
                  to="/" 
                  className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
                >
                  What is Ctrl AI?
                </Link>
                <Link 
                  to="#"
                  onClick={() => window.location.href = 'https://www.skool.com/ctrlai'}
                  className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
                >
                  Join Incubator
                </Link>
                <Link 
                  to="#"
                  onClick={() => window.location.href = 'https://ctrlai.ai'}
                  className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
                >
                  CtrlAI.ai
                </Link>
              </div>
            )}
          </nav>
          <div className="flex items-center space-x-4 h-full">
            {currentUser && (
              <Button 
                onClick={signOut} 
                variant="outline"
                className="text-sm py-3 px-3"
              >
                Log out
              </Button>
            )}
            <ThemeToggle />
          </div>
        </div>

        {/* Mobile Menu Button */}
        <button 
          className="md:hidden p-2 flex items-center"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <Menu className="h-5 w-5" />
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden absolute top-[50px] left-0 right-0 bg-white dark:bg-gray-950 border-t border-gray-200 dark:border-gray-800">
          <nav className="flex flex-col space-y-4 px-8 py-4">
            {isAIDomain ? (
              // AI domain navigation items
              <div className="flex flex-col space-y-4">
                {currentUser && (
                  <>
                    <Link 
                      to="/dashboard" 
                      className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      Dashboard
                    </Link>
                    <Link 
                      to={`/u/${currentUser.uid}`} 
                      className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      My Profile
                    </Link>
                    <Link 
                      to="/settings" 
                      className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      Settings
                    </Link>
                  </>
                )}
              </div>
            ) : (
              // Original navigation items for ctrlai.com
              <div className="flex flex-col space-y-4">
                <Link 
                  to="/" 
                  className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  What is Ctrl AI?
                </Link>
                <Link 
                  to="#"
                  onClick={() => {
                    window.location.href = 'https://www.skool.com/ctrlai';
                    setIsMobileMenuOpen(false);
                  }}
                  className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                >
                  Join Incubator
                </Link>
                <Link 
                  to="#"
                  onClick={() => {
                    window.location.href = 'https://ctrlai.ai';
                    setIsMobileMenuOpen(false);
                  }}
                  className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                >
                  CtrlAI.ai
                </Link>
              </div>
            )}
            <div className="flex items-center space-x-4 pt-4">
              {currentUser && (
                <Button onClick={() => { signOut(); setIsMobileMenuOpen(false); }} variant="outline">
                  Log out
                </Button>
              )}
              <ThemeToggle />
            </div>
          </nav>
        </div>
      )}
      <SignInModal isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)} />
    </header>
  );
};

export default HeaderNavigation;
